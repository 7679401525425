import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdArrowDownward } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Spectravo – створення незабутніх вражень
			</title>
			<meta name={"description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:title"} content={"Головна | Spectravo – створення незабутніх вражень"} />
			<meta property={"og:description"} content={"Ваш головний вибір для виключного планування та організації заходів"} />
			<meta property={"og:image"} content={"https://spectravo.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://spectravo.com/img/3463464.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://spectravo.com/img/3463464.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://spectravo.com/img/3463464.png"} />
			<meta name={"msapplication-TileImage"} content={"https://spectravo.com/img/3463464.png"} />
		</Helmet>
		<Components.Header />
		<Section
			background="linear-gradient(0deg,rgba(4, 8, 12, 0.4) 0%,rgba(4, 8, 12, 0.4) 100%),--color-darkL2 url(https://spectravo.com/img/1.jpg) center/cover"
			padding="80px 0"
			sm-padding="40px 0"
			color="--light"
			font="--base"
		>
			<Box margin="-16px -16px -16px -16px" display="flex">
				<Box display="flex" padding="16px 16px 16px 16px" width="75%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text color="--lightD2" letter-spacing="1px" text-transform="uppercase" margin="0">
							Створення незабутніх вражень
						</Text>
						<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0">
							Spectravo
						</Text>
					</Box>
				</Box>
			</Box>
			<Box text-align="center" margin="196px 0 0 0">
				<Text margin="8px 0" text-transform="uppercase">
				Про компанію
				</Text>
				<Icon category="md" margin="0 auto" icon={MdArrowDownward} />
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="20%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			/>
			<Box
				width="85%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--base">
				У компанії Spectravo ми вважаємо, що кожна подія – це унікальна можливість розповісти історію. Маючи багаторічний досвід роботи у цій галузі, наша команда відданих своїй справі професіоналів прагне забезпечити індивідуальне обслуговування з урахуванням ваших потреб. Ми спеціалізуємося на створенні індивідуальних заходів, які відображають ваш стиль, смак та індивідуальність. Від інтимних зустрічей до грандіозних урочистостей - наша скрупульозна увага до деталей забезпечить вам безпроблемний досвід, що не викликає стресу.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box padding="20px 72px 20px 72px" border-radius="24px" margin="0px 0px 64px 0px" sm-padding="60px 36px 60px 36px">
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Чому варто вибрати Spectravo?
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Команда експертів
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наша команда складається з досвідчених професіоналів з великим досвідом у плануванні та організації заходів. У кожний проект ми привносимо креативність, інновації та уважне ставлення до деталей.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Індивідуальні послуги 
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми пропонуємо індивідуальні рішення, які відповідають вашим специфічним вимогам, щоб ваш захід був унікальним.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Планування без стресу 
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Наша мета – зробити процес планування максимально гладким та приємним. Ми беремо на себе всю логістику, щоб ви могли зосередитись на насолоді своєю подією.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Надійні постачальники 
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Ми співпрацюємо з надійними постачальниками та продавцями, щоб забезпечити першокласні послуги, від кейтерингу та розваг до квіткових композицій та фотозйомки.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section background="--color-darkL2" padding="60px 0" sm-padding="40px 0">
			<Box
				margin="-16px -16px -16px -16px"
				padding="0px 0px 0px 0px"
				display="flex"
				width=" "
				flex-wrap="wrap"
			>
				<Box padding="16px 16px 16px 16px" display="flex" width="50%" lg-width="100%">
					<Box
						padding="98px 64px 98px 64px"
						display="flex"
						flex-direction="column"
						background="--color-light"
						color="--dark"
					>
						<Text margin="12px 0" font="normal 900 42px/1.2 --fontFamily-sans">
						Додаткові послуги
						</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
					Spectravo виходить за межі традиційного планування заходів. Ми пропонуємо цілу низку додаткових послуг, які покращать ваш досвід, включаючи брендування заходи, інтерактив та персоналізовані подарунки для ваших гостей. Наш інноваційний підхід гарантує, що ваш захід виділятиметься і залишить незабутнє враження у всіх присутніх.
					</Text>
					</Box>
				</Box>
				<Box display="flex" width="50%" flex-wrap="wrap" lg-width="100%">
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="100%"
						lg-width="33.3333%"
						md-width="100%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 624px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://spectravo.com/img/2.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://spectravo.com/img/3.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
					<Box
						padding="16px 16px 16px 16px"
						display="flex"
						width="50%"
						lg-width="33.3333%"
						md-width="50%"
						sm-width="100%"
					>
						<Box
							padding="0px 0px 296px 0px"
							display="flex"
							flex-direction="column"
							background="url(https://spectravo.com/img/4.jpg) center/cover"
							width="100%"
							lg-padding="0px 0px 248px 0px"
							sm-padding="0px 0px 380px 0px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});